import * as React from "react";

import { graphql, Link } from "gatsby";

import { sbEditable } from "@storyblok/storyblok-editable";
import useStoryblok from "../lib/storyblok";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import DynamicComponent from "../components/dynamicComponent/DynamicComponent";
import Section from "../components/section/Section";

const Contact = ({ data, location }) => {

  let story = data.storyblokEntry;
  story = useStoryblok(story, location);

  const components = story.content.body.map(blok => { 
      return (<DynamicComponent blok={blok} key={blok._uid} />) 
  });

  return (
    <Layout>
      <div {...sbEditable(story.content)}>
        <Seo title="Contact" />
        {components}
      </div>
    </Layout>
  );

};

export default Contact;

export const query = graphql`
  query ContactQuery {
    storyblokEntry(full_slug: {eq: "contact"}) {
      content
      name
    }
  }
`;